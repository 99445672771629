import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const GameRoomDesignPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Game Room Design" />
      <div className="max-w-7xl mt-32 mx-auto p-4 w-full">
        <h2 className="font-bold leading-none mb-4 text-center text-xl text-brand-blue uppercase">
          Sample 01
        </h2>
        <Image fluid={data.Sample1.childImageSharp.fluid} />
        <br />
        <br />
        <br />
        <h2 className="font-bold leading-none mb-4 text-center text-xl text-brand-blue uppercase">
          Sample 02
        </h2>
        <Image fluid={data.Sample2.childImageSharp.fluid} />
      </div>
    </Layout>
  );
};

GameRoomDesignPage.propTypes = {
  data: PropTypes.object,
};

export default GameRoomDesignPage;

export const query = graphql`
  query GameRoomDesignPageQuery {
    Sample1: file(relativePath: { eq: "sample-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Sample2: file(relativePath: { eq: "sample-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
